import React from "react";
import { rectangleBlack } from "../../assets/images/Home";

function Hero() {
  return (
    <section className="w-full h-[371px] relative bg-[#0a0a0a] overflow-hidden">
      <div className="px-4 flex max-w-[1280px] h-full flex-col justify-end mx-auto">
        <div className="flex gap-10 flex-col pb-16 z-[1]">
          <div className="text-6xl font-semibold text-white tracking-wider">
            Our Projects
          </div>
          <div className="text-gray-600 text-2xl ">{"Our's"} some projects</div>
        </div>
      </div>
      <img
        className="absolute xsm:w-auto xsm:h-[371px] object-cover xsm:right-0 md:-right-40 lg:right-10 top-0 z-0"
        src={rectangleBlack}
        alt="backgroundHeroProjects"
      />
    </section>
  );
}

export default Hero;
