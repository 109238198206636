import { useState } from "react";
import { Minus } from "../../assets/icons/Minus";
import { Plus } from "../../assets/icons/Plus";
import { secondary } from "../../assets/strings/variables";
import React from "react";
const FAQContainer = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  };
  return (
    <>
      <div
        className="flex justify-between mb-6 items-center cursor-pointer"
        onClick={handleOpen}
      >
        <div className="xsm:text-xl sm:text-2xl font-semibold">
          {props?.data?.question}
        </div>
        <div>{isOpen ? <Minus /> : <Plus />}</div>
      </div>
      <div
        className={`text-[${secondary}] text-base w-11/12 leading-snug text-justify xsm:mx-auto md:mx-0 ${
          isOpen ? "block" : "hidden"
        }`}
      >
        {props?.data?.answer}
      </div>
    </>
  );
};

export default FAQContainer;
