import React from "react";
import { base } from "../../assets/strings/variables";

function ProjectTotal() {
  return (
    <section id="extra-2" className={`bg-[${base}] text-white w-full`}>
      <div className="h-full mx-auto xsm:px-4 xsm:py-4 xl:px-0 md:py-16 max-w-[1280px]">
        <div className="xsm:text-2xl md:text-4xl font-semibold max-w-[1024px]">
          As a Software Developer Service, we work closely with our clients and
          reach the touchpoints.
        </div>
        <div className="grid xsm:grid-cols-1 sm:grid-cols-[minmax(0,340px)_minmax(0,340px)] mt-10 gap-7 max-w-[1024px]">
          <div className="flex xsm:justify-between sm:justify-around">
            <div>
              <div className="text-6xl font-semibold mt-3">50+</div>
              <div className="text-2xl mt-4">Projects</div>
            </div>
            <div>
              <div className="text-6xl font-semibold mt-3">20+</div>
              <div className="text-2xl mt-4">Teams</div>
            </div>
          </div>
          <div className="flex xsm:justify-between sm:justify-around sm:gap-4">
            <div>
              <div className="text-6xl font-semibold mt-3">3</div>
              <div className="text-2xl mt-4">Cities</div>
            </div>
            <div className="text-2xl font-semibold flex flex-col space-y-3">
              <div>Jakarta</div>
              <div>Yogyakarta</div>
              <div>Makassar</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectTotal;
