import React from "react"
import { Link } from "react-router-dom"
import { Mouse } from "../../assets/icons/Mouse"
import { rectangle } from "../../assets/images/Home"
import { base, PICNumber } from "../../assets/strings/variables"

function Hero() {
    return (
        <section className={`section bg-[${base}] pt-[106px]`}>
            <div></div>
            {/* hero content */}
            <div className="hero-content">
                {/* grid 1 : Hero Description container */}
                <div>
                    <div className="hero-title">Empower Your Business with AI-Driven Solutions</div>
                    <div className="hero-description">
                        CODR delivers custom AI implementations that transform operations, optimize performance, and
                        drive growth.
                    </div>
                    <div className="hero-button-container">
                        <a href={`https://wa.me/${PICNumber}`} target="_blank" rel="noreferrer">
                            <button type="button" className={`bg-[#FFF] text-[#1F1F1F] py-3 px-7 rounded-full`}>
                                Reach us
                            </button>
                        </a>
                        {/* <Link to="/projects">
              <button
                type="button"
                className={`text-[#FFF] border-white border bg-[${base}] py-3 px-7 rounded-full`}
              >
                Check our works
              </button>
            </Link> */}
                    </div>
                </div>
                {/* grid 2 Rectangle Image*/}
                <div>
                    <img src={rectangle} alt="" />
                </div>
                {/* Grid Span 2 : Scroll Down Container */}
                <div className=" xsm:hidden md:flex md:col-span-2 gap-3 flex-col items-center text-[#898989] text-center w-full justify-center">
                    <Mouse />
                    <div>Scroll Down</div>
                </div>
                {/* grid button mobile */}
                <div className="hero-button-mobile">
                    <a href={`https://wa.me/${PICNumber}`} target="_blank" rel="noreferrer">
                        <button type="button" className={`bg-[#FFF] text-[#1F1F1F] py-3 px-7 rounded-full`}>
                            Reach us
                        </button>
                    </a>
                    {/* <Link to="/projects">
                        <button
                            type="button"
                            className={`text-[#FFF] border-white border bg-[${base}] py-3 px-7 rounded-full`}
                        >
                            Check our works
                        </button>
                    </Link> */}
                </div>
            </div>
        </section>
    )
}

export default Hero
