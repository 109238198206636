import React from "react";
import { base, PICNumber } from "../../assets/strings/variables";

function Contact() {
  return (
    <section className="w-full h-[384px] bg-[#F9F9F9]">
      <div className="flex flex-col justify-center items-center gap-5 w-full h-full xsm:text-center md:text-left">
        <div className="text-gray-400">{"Let's"} Work</div>
        <div className="font-semibold text-[32px] xsm:text-xl">
          Is there a challenge your company needs help solving? {"We'd"} love to
          discuss it
        </div>
        <a href={`https://wa.me/${PICNumber}`} target="_blank" rel="noreferrer">
          <button
            className={`text-[#101223] bg-[${base}] py-3 px-7 font-semibold rounded-full`}
          >
            <div className="flex items-center align-middle space-x-2 text-white whitespace-nowrap">
              <div>Contact Us</div>
            </div>
          </button>
        </a>
      </div>
    </section>
  );
}

export default Contact;
