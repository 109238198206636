import React from "react"
import { secondary } from "../../assets/strings/variables"
import { whyCODRdata } from "../../pages/Homepage/homepageData"

function WhyCODR() {
  return (
    <section
      id="why-codr"
      className="h-full grid grid-cols-1 px-6 sm:px-10 xl:px-0 max-w-[1200px] w-full py-14 sm:py-20  m-auto"
    >
      <div className="text-[32px] text-center font-semibold ">Why CODR?</div>
      {/* container */}
      <div className="py-6 sm:py-10  grid">
        {/* grid content */}
        <div className="grid xsm:grid-cols-1 md:grid-cols-2 xsm:gap-3 md:gap-5 lg:grid-cols-4">
          {whyCODRdata.map(({ title, desc, icon: Icon }, idx) => (
            <div
              className="xsm:p-2 md:p-4 md:h-[240px]"
              key={`value-${idx}`}
            >
              <div className="rounded-full h-[40px] w-[40px] mb-6 inline-block">
                <div className="flex items-center justify-center h-full">
                  <Icon />
                </div>
              </div>
              <div>
                <div className="font-semibold mb-2 xsm:text-base md:text-xl">
                  {title}
                </div>
                <div className={`text-[${secondary}] xsm:text-sm md:text-base`}>
                  {desc}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default WhyCODR
