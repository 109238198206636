import React from "react";
export const Plus = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0002 4.19995C14.7734 4.19995 15.4002 4.82675 15.4002 5.59995V12.6H22.4002C23.1734 12.6 23.8002 13.2268 23.8002 14C23.8002 14.7731 23.1734 15.4 22.4002 15.4H15.4002V22.4C15.4002 23.1731 14.7734 23.7999 14.0002 23.7999C13.227 23.7999 12.6002 23.1731 12.6002 22.4V15.4H5.6002C4.827 15.4 4.2002 14.7731 4.2002 13.9999C4.2002 13.2268 4.827 12.5999 5.6002 12.5999L12.6002 12.6V5.59995C12.6002 4.82675 13.227 4.19995 14.0002 4.19995Z"
        fill="#1F1F1F"
      />
    </svg>
  );
};
