import React from "react"
import { secondary } from "../../assets/strings/variables"
import { ourSolutionsData } from "../../pages/Homepage/homepageData"

function OurSolution() {
    return (
        <section id="our-solution" className="h-full px-6 sm:px-10 xl:px-0 max-w-[1200px] m-auto grid grid-cols-1 w-full py-14 sm:py-20">
            <div className="text-[32px] text-center font-semibold ">Our Solutions</div>
            {/* container */}
            <div className="py-6 sm:py-10  grid">
                {/* grid content */}
                <div className="grid grid-cols-1 gap-3 sm:gap-5 md:grid-cols-3 m-auto">
                    {ourSolutionsData.map(({ title, desc, image }, idx) => (
                        <div className="rounded-xl max-w-[384px] xsm:p-3 md:p-6 h-auto bg-[#F7F7F7]" key={`solution-${idx}`}>
                            <div className="flex flex-col h-full w-full mb-6 inline-block">
                                <img src={image} alt={title} className="justify-self-center justify-items-center"/>
                                <div className="pt-5">
                                    <div className="font-semibold mb-2 xsm:text-base md:text-xl">{title}</div>
                                    <div className={`text-[${secondary}] xsm:text-sm md:text-base`}>{desc}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default OurSolution
