import React from "react";
export const Minus = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.59961 12C3.59961 11.3373 4.13687 10.8 4.79961 10.8L19.1996 10.8C19.8624 10.8 20.3996 11.3373 20.3996 12C20.3996 12.6628 19.8624 13.2 19.1996 13.2L4.79961 13.2C4.13687 13.2 3.59961 12.6628 3.59961 12Z"
        fill="#111827"
      />
    </svg>
  );
};
