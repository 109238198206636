import React from "react"
export const PuzzleIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 4C4 1.79086 5.79086 0 8 0C10.2091 0 12 1.79086 12 4H15C15.5523 4 16 4.44772 16 5V8C18.2091 8 20 9.7909 20 12C20 14.2091 18.2091 16 16 16V19C16 19.5523 15.5523 20 15 20H1C0.44772 20 0 19.5523 0 19V5C0 4.44772 0.44772 4 1 4H4ZM8 2C6.89543 2 6 2.89543 6 4C6 4.23554 6.0403 4.45952 6.11355 4.66675C6.22172 4.97282 6.17461 5.31235 5.98718 5.57739C5.79974 5.84243 5.49532 6 5.17071 6H2V18H14V14.8293C14 14.5047 14.1576 14.2003 14.4226 14.0128C14.6877 13.8254 15.0272 13.7783 15.3332 13.8865C15.5405 13.9597 15.7645 14 16 14C17.1046 14 18 13.1046 18 12C18 10.8954 17.1046 10 16 10C15.7645 10 15.5405 10.0403 15.3332 10.1135C15.0272 10.2217 14.6877 10.1746 14.4226 9.9872C14.1576 9.7997 14 9.4953 14 9.1707V6H10.8293C10.5047 6 10.2003 5.84243 10.0128 5.57739C9.8254 5.31235 9.7783 4.97282 9.8865 4.66675C9.9597 4.45952 10 4.23555 10 4C10 2.89543 9.1046 2 8 2Z"
                fill="#0A0A0A"
            />
        </svg>
    )
}
