import React from "react";
import { Whatsapp } from "../assets/icons/Whatsap";

export default function LiveChat() {
  const PICNumber = "6287881930558";
  return (
    <div className={"fixed z-[998] right-6 bottom-16"}>
      <a href={`https://wa.me/${PICNumber}`} target="_blank" rel="noreferrer">
        <div
          style={{
            boxShadow:
              "box-shadow: 0px 4px 8px rgba(65, 78, 98, 0.12), 0px 0px 1px rgba(65, 78, 98, 0.05)",
          }}
          className="bg rounded-full bg-[#f2f4f7] border p-4 cursor-pointer"
        >
          <Whatsapp className={"fill-white"} />
        </div>
      </a>
    </div>
  );
}
