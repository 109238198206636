import Layout from "../../components/Layout/Layout";
import React from "react";
import Hero from "../../components/Home/Hero";
import Client from "../../components/Home/Client";
import About from "../../components/Home/About";
import Services from "../../components/Home/Services";
import Values from "../../components/Home/Values";
import Projects from "../../components/Home/Projects";
import FAQ from "../../components/Home/FAQ";
import ProjectTotal from "../../components/Home/ProjectTotal";
import Consult from "../../components/Home/Consult";
import WhyCODR from "../../components/Home/WhyCODR";
import OurSolution from "../../components/Home/OurSolution";

const Homepage = () => {
  return (
    <Layout>
      <div>
        <Hero />
        <Client />
        {/* <About /> */}
        {/* <Services /> */}
        <WhyCODR />
        <OurSolution />
        <Values />
        {/* <ProjectTotal /> */}
        {/* <Projects /> */}
        {/* <FAQ /> */}
        <Consult />
      </div>
    </Layout>
  );
};

export default Homepage;
