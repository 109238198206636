import React, { useState } from "react"
import { useEffect } from "react"
import { useRef } from "react"
import { Link } from "react-router-dom"
import { projectImage } from "../../assets/images/Home"
import { secondary } from "../../assets/strings/variables"
import { projectCategory } from "../../pages/Homepage/homepageData"

function Services() {
  const [categories, setCategories] = useState([...projectCategory])
  const sliderRef = useRef(null)

  const handleResize = (index) => {
    handleActive(index)
    let contentIndex =
      (sliderRef.current.offsetWidth / projectCategory.length) * index
    sliderRef.current.style.translate = -contentIndex + "px"
  }

  useEffect(() => {
    const listen = window.addEventListener("resize", handleResize.bind(this, 0))
    return removeEventListener(listen, null)
  }, [])

  const handleActive = (index) => {
    setCategories((prev) =>
      prev.map((cat, indx) => ({
        ...cat,
        active: indx === index ? true : false,
      }))
    )
  }

  const handleClick = (index) => {
    handleResize(index)
    handleActive(index)
  }

  return (
    <section
      id="our-services"
      className="h-full w-full grid grid-cols-1 px-4 sm:px-10 py-16"
    >
      {/* Title Container */}
      <div className="">
        <div className="text-[32px] font-semibold">Our Services</div>
        <div className={`text-[${secondary}] text-2xl my-5`}>
          We provide various
          <span className="font-semibold"> digital solutions</span> and
          <span className="font-semibold"> strategy</span>, dedicated to helping
          you <span className="font-semibold">grow digitally.</span>
        </div>
      </div>
      {/* Services Container */}
      <div className="relative overflow-hidden">
        {/* Button Container */}
        <div className="xsm:grid xsm:text-sm xsm:grid-cols-2 xsm:gap-3 md:flex md:gap-4 flex-wrap">
          {categories.map((category, ind) => (
            <button
              type="button"
              key={`${ind}`}
              onClick={() => handleClick(ind)}
              className={`py-3 px-4 m-0 lg:px-7 font-semibold rounded-full ${
                category?.active
                  ? "text-[#FFF] bg-[#0A0A0A]"
                  : "text-[#898989] bg-[#F7F7F7]"
              }`}
            >
              {category?.name}
            </button>
          ))}
          {/* TEMPORARY: Because cover image still using dummy image */}
          {/* <button
            type="button"
            className={`py-3 px-4 m-0 lg:px-7 font-semibold rounded-full text-[#898989] bg-[#F7F7F7]`}
          >
            Bussiness Intelegence
          </button>
          <button
            type="button"
            className={`py-3 px-4 m-0 lg:px-7 font-semibold rounded-full text-[#898989] bg-[#F7F7F7]`}
          >
            Internet of Things
          </button>
          <button
            type="button"
            className={`py-3 px-4 m-0 lg:px-7 font-semibold rounded-full text-[#898989] bg-[#F7F7F7]`}
          >
            Enterprise Resource Planning
          </button> */}
        </div>
        {/* Services Content Container */}
        <div
          ref={sliderRef}
          className={`flex flex-row min-w-fit pt-5 transition-[translate] duration-500 ease-linear xsm:mt-4 md:mt-0`}
        >
          {categories.map((category, index) => (
            <div className={`h-full w-screen flex`} key={index}>
              {/* Content */}
              <div className=" w-full h-full grid xsm:grid-cols-1 md:grid-cols-2 xsm:gap-3 md:gap-0 justify-center items-center">
                {/* grid Content 1 */}
                <div className="">
                  {/* Title Content */}
                  <div className="font-semibold text-black text-2xl mb-3 ">
                    {category.name}
                  </div>
                  {/* Content Description */}
                  <div className="pr-16">{category?.description}</div>
                  {/* <div className="font-semibold mt-10">
                    <Link to="projects">See the projects -{">"}</Link>
                  </div> */}
                </div>
                {/* grid Content 2 */}
                <div className="h-[328px] rounded-sm overflow-hidden">
                  <img
                    src={category?.image}
                    alt=""
                    loading="lazy"
                    className="h-full object-contain mx-auto"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Services
