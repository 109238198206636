export default [
  {
    id: "WA",
    name: "Web App",
    active: true,
    projects: [
      {
        id: "WA-1",
        title: "Toyota Trust E-Commerce",
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. At in sed in
          libero dictumst. Quis leo nascetur gravida leo.`,
        details: {
          overview:
            "Toyota TRUST adalah layanan resmi Toyota yang menjual mobil bekas Toyota berkualitas dan bersertifikasi sehingga pelanggan dapat menikmati garansi resmi dari PT Toyota Astra Motor. Setiap mobil bekas Toyota sudah melalui proses pengecekan di 203 titik, sehingga dapat dipastikan aman dan transparan.",
          approach:
            "Sistem Toyota TRUST berbasiskan Web Application yang terintergasi dengan aplikasi inspeksi, aplikasi sales, dan aplikasi Enterprise Resource Planning (ERP).\nTeknologi yang digunakan adalah: HTML, CSS, JavaScript, react.Js, Node.Js, Express.Js, React. Native",
          result:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit non aliquam, aliquam lorem. Suspendisse dui nunc sem aliquet pulvinar arcu. Blandit viverra sollicitudin feugiat libero semper quisque arcu. Ac pretium diam nisi ut eu vel felis fringilla sed. Ac praesent vel, amet, odio porttitor. Nec egestas fermentum ultricies tempor consectetur odio pellentesque. Tristique ultrices hendrerit mauris nibh orci in neque risus dictum. Leo, fames leo fusce a diam. Nibh auctor elit tellus nibh.",
          // productPreview: ["tt1.png", "tt2.png", "tt3.png", "tt4.png"],
          cardImage: "/tt/tt1.png",
          productPreview: "tt/tt.png",
          bannerImage: "/tt/banner_tt.png",
        },
      },
      {
        id: "WA-2",
        title: "Arista Group",
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. At in sed in
          libero dictumst. Quis leo nascetur gravida leo.`,
        details: {
          overview:
            "ARISTA merupakan perusahaan berskala nasional yang mengawali bisnis dalam industri otomotif pada tahun 2003 sebagai dealer resmi kendaraan dengan memberikan layanan lengkap mulai penjualan hingga service kendaraan. Arista Group telah dipercaya untuk memegang beberapa merek kendaraaan yaitu Honda, Suzuki, Mitsubishi, Hino, Wuling, Mercedes Benz, dan Hyundai, maupun dari pemegang merek sepeda motor Yamaha.",
          approach:
            "Sistem dibangun berbasis Web-Application untuk mendukung operasional Arista Group berupa: Sales Force Application, Online Catalog Dealership, Home Service, Booking Bengkel, dan analytic dashboard untuk monitoring penjualan.\nTech Stack: HTML, CSS, JavaScript, PHP, Laravel, Odoo Integrated",
          result:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit non aliquam, aliquam lorem. Suspendisse dui nunc sem aliquet pulvinar arcu. Blandit viverra sollicitudin feugiat libero semper quisque arcu. Ac pretium diam nisi ut eu vel felis fringilla sed. Ac praesent vel, amet, odio porttitor. Nec egestas fermentum ultricies tempor consectetur odio pellentesque. Tristique ultrices hendrerit mauris nibh orci in neque risus dictum. Leo, fames leo fusce a diam. Nibh auctor elit tellus nibh.",
          cardImage: "/arista/arista1.png",
          productPreview: "arista/arista.png",
          bannerImage: "/arista/banner_arista.png",
        },
      },
      {
        id: "WA-3",
        title: "Jagomaterial (jagomaterial.id)",
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. At in sed in
          libero dictumst. Quis leo nascetur gravida leo.`,
        details: {
          overview:
            "Jago Material adalah sebuah platform e-commerce yang menyediakan kebutuhan bangunan, baik itu peralatan maupun bahan bangunan. Jago material juga menyediakan pengiriman bahan dan peralatan bangunan ke kota kota besar seperti Jakarta, Semarang, Surabaya & Banjarmasin.",
          approach:
            "Teknologi yang digunakan adalah: PHP, Laravel, HTML, CSS, JavaScript, Midtrans, SiCepat Integration, Odoo Integrated",
          result:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit non aliquam, aliquam lorem. Suspendisse dui nunc sem aliquet pulvinar arcu. Blandit viverra sollicitudin feugiat libero semper quisque arcu. Ac pretium diam nisi ut eu vel felis fringilla sed. Ac praesent vel, amet, odio porttitor. Nec egestas fermentum ultricies tempor consectetur odio pellentesque. Tristique ultrices hendrerit mauris nibh orci in neque risus dictum. Leo, fames leo fusce a diam. Nibh auctor elit tellus nibh.",
          cardImage: "/jago/jago1.png",
          productPreview: "jago/jago.png",
          bannerImage: "/jago/banner_jago.png",
        },
      },
      {
        id: "WA-4",
        title: "Hybid Lelang (hybid.id)",
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. At in sed in
          libero dictumst. Quis leo nascetur gravida leo.`,
        details: {
          overview:
            "HYBID merupakan produk dari PT. Balai Lelang Anugerah adalah balai lelang yang menyediakan jasa lelang terbuka dengan pengelolaan yang professional, transparan dan terpercaya. Proses lelang dapat dilakukan secara hybrid, dimana customer dapat melakukan Bid melalui website ataupun datang ke lokasi lelang. Platform lelang yang dibangun meliputi. Website lelang customer, website untuk konduktor lelang, dan admin panel untuk mengelola operasional internal perusahaan.",
          approach:
            "Platform lelang yang dibangun meliputi. Website lelang customer, website untuk konduktor lelang, dan admin panel untuk mengelola operasional internal perusahaan. Pada aplikasi lelang ini sistem menggunakan websocket untuk mengakomodir kebutuhan event lelang secara real-time. \nTech Stack: HTML, CSS, JavaScript, React.Js, PHP, Laravel, Websocket, Xendit integrated",
          result:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit non aliquam, aliquam lorem. Suspendisse dui nunc sem aliquet pulvinar arcu. Blandit viverra sollicitudin feugiat libero semper quisque arcu. Ac pretium diam nisi ut eu vel felis fringilla sed. Ac praesent vel, amet, odio porttitor. Nec egestas fermentum ultricies tempor consectetur odio pellentesque. Tristique ultrices hendrerit mauris nibh orci in neque risus dictum. Leo, fames leo fusce a diam. Nibh auctor elit tellus nibh.",
          cardImage: "/hybid/hybid1.png",
          productPreview: "hybid/hybid.png",
          bannerImage: "/hybid/banner_hybid.png",
        },
      },
    ],
  },
  {
    id: "MA",
    name: "Mobile App",
    active: false,
    projects: [
      {
        id: "MA-1",
        title: "Digital Appraisal",
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. At in sed in
          libero dictumst. Quis leo nascetur gravida leo.`,
        details: {
          overview:
            "Digital Appraisal adalah aplikasi mobile untuk mengakomodir proses inspeksi kendaraan untuk memberikan penilaian terhadap kualitas kendaraan yang akan diperjualbelikan. Pada aplikasi ini, setiap kendaraan yang sudah selesai di inspeksi, akan diberikan skor sebagai acuan untuk pemberian rekomendasi harga kendaraan.",
          approach:
            "Sistem dibangun dengan framework React Native (cross-platform framework) agar dapat diakses oleh pengguna Android dan IOS. Sistem ini terintegrasi dengan sistem aplikasi sales dan Enterprise Resource Planning (ERP).\nTech Stack: HTML, CSS, JavaScript, React.Js, Node.Js, Express.Js, React. Native",
          result:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit non aliquam, aliquam lorem. Suspendisse dui nunc sem aliquet pulvinar arcu. Blandit viverra sollicitudin feugiat libero semper quisque arcu. Ac pretium diam nisi ut eu vel felis fringilla sed. Ac praesent vel, amet, odio porttitor. Nec egestas fermentum ultricies tempor consectetur odio pellentesque. Tristique ultrices hendrerit mauris nibh orci in neque risus dictum. Leo, fames leo fusce a diam. Nibh auctor elit tellus nibh.",
          cardImage: "/digital/digital1.png",
          productPreview: "digital/digital.png",
          bannerImage: "/digital/banner_digital.png",
        },
      },
      {
        id: "MA-2",
        title: "Rumah Siap Kerja",
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. At in sed in
          libero dictumst. Quis leo nascetur gravida leo.`,
        details: {
          overview:
            "Rumah Siap Kerja merupakan Platform pendidikan untuk pengembangan skills professional dan wirausaha baik secara soft skill maupun hardskills. yang menyediakan sertifikat resmi untuk setiap peserta yang mengikuti sesi pelatihan dari Rumah Siap Kerja. Aplikasi ini menyediakan daftar pelatihan dengan kategori bidang pelatihan, informasi detail materi, rincial silabus, dan fitur pembayaran online untuk pembelian materi",
          approach:
            "Aplikasi Rumah Siap Kerja dibangun menggunakan framework react native (cross-platform framework) agar aplikasi dapat diakses oleh pengguna Andorid dan IOS sekaligus. User dapat mengakses aplikasi Rumah Siap Kerja melalui: \nGoogle play: https://play.google.com/store/apps/details?id=com.rumahsiapkerja",
          result:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit non aliquam, aliquam lorem. Suspendisse dui nunc sem aliquet pulvinar arcu. Blandit viverra sollicitudin feugiat libero semper quisque arcu. Ac pretium diam nisi ut eu vel felis fringilla sed. Ac praesent vel, amet, odio porttitor. Nec egestas fermentum ultricies tempor consectetur odio pellentesque. Tristique ultrices hendrerit mauris nibh orci in neque risus dictum. Leo, fames leo fusce a diam. Nibh auctor elit tellus nibh.",
          cardImage: "/rsk/rsk1.png",
          productPreview: "rsk/rsk.png",
          bannerImage: "/rsk/banner_rsk.png",
        },
      },
      {
        id: "MA-3",
        title: "Vinmo",
        description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. At in sed in
          libero dictumst. Quis leo nascetur gravida leo.`,
        details: {
          overview:
            "Vinmo merupakan aplikasi untuk karyawan melakukan penarikan gaji sebelum tanggal payroll. Aplikasi ini dapat digunakan oleh perusahaan dan karyawan-karyawan yang ada di perusahaan tersebut. Melalu aplikasi Vinmo, karyawan dapat mengajukan pencairan gaji lebih awal secara real-time, mengatur rekening penerima, dan melihat sisa gaji yang tersedia untuk tanggal gajian.",
          approach:
            "Aplikasi Vinmo dibangun menggunakan framework react native (cross-platform framework) agar aplikasi dapat diakses oleh pengguna Andorid dan IOS sekaligus. Aplikasi Vinmo juga terintegrasi menggunakan Xendit sebagai penyedia Payment Gateway agar user dapat bertransaksi secara real-time. \n Website: https://vinmo.co.id/\nMobile App dapat diunduh di Play Store dan App Store melalui link berikut:\n•	Play Store: https://play.google.com/store/apps/details?id=com.vinmo \n•	App Store: https://apps.apple.com/id/app/vinmo/id1572793349?l=id ",
          result:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit non aliquam, aliquam lorem. Suspendisse dui nunc sem aliquet pulvinar arcu. Blandit viverra sollicitudin feugiat libero semper quisque arcu. Ac pretium diam nisi ut eu vel felis fringilla sed. Ac praesent vel, amet, odio porttitor. Nec egestas fermentum ultricies tempor consectetur odio pellentesque. Tristique ultrices hendrerit mauris nibh orci in neque risus dictum. Leo, fames leo fusce a diam. Nibh auctor elit tellus nibh.",
          cardImage: "/vinmo/vinmo1.png",
          productPreview: "vinmo/vinmo.png",
          bannerImage: "/vinmo/banner_vinmo.png",
        },
      },
    ],
  },
  // {
  //   name: "UI/UX Design",
  //   active: false,
  //   projects: [],
  // },
  // {
  //   name: "Bussiness Intelegence",
  //   active: false,
  //   projects: [],
  // },
  // {
  //   name: "Internet of Things",
  //   active: false,
  //   projects: [],
  // },
  // {
  //   name: "Growth Strategy",
  //   active: false,
  //   projects: [],
  // },
  // {
  //   name: "Digital Marketing",
  //   active: false,
  //   projects: [],
  // },
  // {
  //   name: "Enterprise Resource Planning",
  //   active: false,
  //   projects: [],
  // },
  // {
  //   name: "Bussiness Process Engineering",
  //   active: false,
  //   projects: [],
  // },
];
