import React from "react"
import { clients } from "../../pages/Homepage/homepageData"

function Client() {
    return (
        <div className="h-full w-full px-6 sm:px-10 xl:px-0 max-w-[1200px] m-auto">
            <div className="py-24">
                <div className="text-center text-[32px] font-semibold mb-10">{/* Our Clients */}</div>
                <div id="client-list-image" className="client-list">
                    <div className="grid grid-cols-2 justify-center items-center gap-3 md:grid-cols-6">
                        {clients.map((client) => (
                            <div key={client.name} className="flex justify-center">
                                <img
                                    src={client.image}
                                    alt={client.name}
                                    className="client-image object-contain mb-6 sm:mb-0"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Client
