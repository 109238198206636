import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { Whatsapp } from "../../assets/icons/Whatsap";
import { headerLogo, headerLogoFull } from "../../assets/images/Nav";
import { PICNumber } from "../../assets/strings/variables";

const Header = ({ active }) => {
  const [toggle, SetToggle] = useState(false);
  const handleToggle = () => {
    SetToggle(!toggle);
  };
  return (
    <header
      id="header"
      className={` fixed top-0 w-full z-10 codr-header ${
        !active ? "codr-header-active " : ""
      }`}
    >
      {/* Header container */}
      <div className="xsm:py-4 md:py-0 flex w-full md:px-10 mx-auto justify-between relative items-center">
        {/* Flex 1 : logo Container */}
        <div
          className={`logoContainer z-[1] ml-4 ${!active ? "hideLogo" : ""}`}
        >
          <img
            src={headerLogoFull}
            className="header-logo-full"
            loading="lazy"
            alt="caready header"
          />
          <img
            src={headerLogo}
            className={`absolute header-logo top-0 left-[21px] z-10
              }`}
            loading="lazy"
            alt="caready header"
          />
        </div>
        {/* Flex 2 : Nav Link Container */}
        <div className="md:flex w-min p-4 items-center">
          {/* <button
            className="xsm:block mx-auto md:hidden w-[25px] h-[25px]"
            onClick={handleToggle}
          >
            <GiHamburgerMenu size={24} />
          </button> */}
          <div
            className={`flex md:flex-row md:static md:space-x-16 md:gap-0 items-center xsm:w-full xsm:h-screen md:h-full xsm:py-5 xsm:bg-[#0a0a0a] md:bg-transparent xsm:gap-3 xsm:absolute xsm:flex-col xsm:justify-center xsm:top-[0%] xsm:right-[0] xsm:transition-transform xsm:duration-300 xsm:ease-linear md:translate-x-[0] ${
              toggle ? "xsm:translate-x-[0%]" : "xsm:translate-x-[100%]"
            }`}
          >
            <button
              className="xsm:absolute top-8 right-5 mx-auto md:hidden w-[25px] h-[25px]"
              onClick={handleToggle}
            >
              <IoClose size={24} />
            </button>
            {/* <NavLink
              to="/"
              className={(navData) =>
                navData.isActive
                  ? "text-white font-semibold"
                  : "hover:text-white hover:font-semibold"
              }
            >
              Home
            </NavLink> */}
            {/* <NavLink
              to="/projects"
              className={(navData) =>
                navData.isActive
                  ? "text-white font-semibold"
                  : "hover:text-white hover:font-semibold"
              }
            >
              Project
            </NavLink> */}
            {/* <NavLink to='/blogs' className="hover:text-white hover:font-semibold" activeClassName="text-white font-semibold">Blog</NavLink> */}
            <a
              href={`https://wa.me/${PICNumber}`}
              target="_blank"
              rel="noreferrer"
            >
              <button className="text-[#101223] bg-[#FFF] py-3 xsm:px-5 md:px-7 font-semibold rounded-full">
                <div className="flex items-center align-middle space-x-2 whitespace-nowrap">
                  <Whatsapp />
                  <div>Contact Us</div>
                </div>
              </button>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
