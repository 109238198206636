import React from "react";
import { useParams } from "react-router";
import Layout from "../../components/Layout/Layout";
import Banner from "../../components/ProjectDetail/Banner";
import Contact from "../../components/Projects/Contact";
import DetailsProject from "./DetailsProject";
import projectList from "../Project/listProjectData";

function ProjectDetail() {
  const { id } = useParams();
  const objId = id.split("-")[0];
  const projectDetail = projectList
    .find((category) => category.id === objId)
    .projects.find((project) => project.id == id);

  return (
    <Layout>
      <Banner project={projectDetail} />
      <DetailsProject project={projectDetail} />
      <Contact />
    </Layout>
  );
}

export default ProjectDetail;
