import React from "react";
import { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import LiveChat from "../live-chat";

const Layout = ({ children }) => {
  const [active, setActive] = useState(true);

  const checkTop = () => {
    const yPos = window.pageYOffset;
    const widthWindow = window.innerWidth;
    let threshold = 90;
    if (widthWindow < 1024) {
      threshold = 45;
    } else {
      threshold = 90;
    }
    if (yPos < threshold) {
      setActive(true);
    } else {
      setActive(false);
    }
  };

  // const scrollToTop = () => {
  //     document.body.scrollTo({
  //         top: 0,
  //         behavior: 'smooth',
  //     })
  //     document.documentElement.scrollTo({
  //         top: 0,
  //         behavior: 'smooth',
  //     });
  // }

  useEffect(() => {
    window.onscroll = () => {
      checkTop();
    };
  }, []);

  return (
    <div className="relative">
      <Header active={active} />
      <div className="min-h-screen " id="content">
        {children}
      </div>
      <Footer />
      {/* <LiveChat /> */}
    </div>
  );
};

export default Layout;
