import React from "react";
export const Mouse = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9998 45.3333C20.4636 45.3333 17.0722 43.9285 14.5717 41.4281C12.0713 38.9276 10.6665 35.5362 10.6665 32V16C10.6665 12.4637 12.0713 9.07235 14.5717 6.57187C17.0722 4.07138 20.4636 2.66663 23.9998 2.66663C27.5361 2.66663 30.9274 4.07138 33.4279 6.57187C35.9284 9.07235 37.3332 12.4637 37.3332 16V32C37.3332 33.7509 36.9883 35.4847 36.3182 37.1024C35.6482 38.7201 34.666 40.1899 33.4279 41.4281C32.1898 42.6662 30.72 43.6483 29.1023 44.3184C27.4846 44.9884 25.7508 45.3333 23.9998 45.3333ZM23.9998 5.33329C21.1709 5.33329 18.4578 6.4571 16.4574 8.45749C14.457 10.4579 13.3332 13.171 13.3332 16V32C13.3332 34.8289 14.457 37.542 16.4574 39.5424C18.4578 41.5428 21.1709 42.6666 23.9998 42.6666C26.8288 42.6666 29.5419 41.5428 31.5423 39.5424C33.5427 37.542 34.6665 34.8289 34.6665 32V16C34.6665 13.171 33.5427 10.4579 31.5423 8.45749C29.5419 6.4571 26.8288 5.33329 23.9998 5.33329Z"
        fill="#898989"
      />
      <path
        d="M23.9998 20C23.6462 20 23.3071 19.8595 23.057 19.6095C22.807 19.3594 22.6665 19.0203 22.6665 18.6667V13.3333C22.6665 12.9797 22.807 12.6406 23.057 12.3905C23.3071 12.1405 23.6462 12 23.9998 12C24.3535 12 24.6926 12.1405 24.9426 12.3905C25.1927 12.6406 25.3332 12.9797 25.3332 13.3333V18.6667C25.3332 19.0203 25.1927 19.3594 24.9426 19.6095C24.6926 19.8595 24.3535 20 23.9998 20Z"
        fill="#898989"
      />
    </svg>
  );
};
