import React from "react"
export const PlantIcon = () => {
    return (
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.99805 0C6.68733 0 9.0224 1.51653 10.1947 3.74104C11.372 2.08252 13.3086 1 15.498 1H18.998V3.5C18.998 7.08985 16.0879 10 12.498 10H10.998V11H15.998V18C15.998 19.1046 15.1026 20 13.998 20H5.99805C4.89348 20 3.99805 19.1046 3.99805 18V11H8.998V9H6.99805C3.13205 9 -0.00195312 5.86599 -0.00195312 2V0H3.99805ZM13.998 13H5.99805V18H13.998V13ZM16.998 3H15.498C13.0128 3 10.998 5.01472 10.998 7.5V8H12.498C14.9833 8 16.998 5.98528 16.998 3.5V3ZM3.99805 2H1.99805C1.99805 4.76142 4.23662 7 6.99805 7H8.998C8.998 4.23858 6.75947 2 3.99805 2Z"
                fill="#0A0A0A"
            />
        </svg>
    )
}
