import React from "react"
import { base, PICNumber, secondary } from "../../assets/strings/variables"

function Consult() {
  return (
    <section className="px-6 sm:px-10 xl:px-0 max-w-[1200px] m-auto w-full h-full pb-10">
      <div
        id="unanswered-question"
        className="text-center mx-auto md:w-[600px]"
      >
        <div className={`text-lg md:text-2xl text-[${secondary}] mb-4`}>
          Still have unaswered questions? Click here for consultation
        </div>
        <a href={`https://wa.me/${PICNumber}`} target="_blank" rel="noreferrer">
          <button
            type="button"
            className={`rounded-full bg-[${base}] text-white font-semibold py-3 px-7`}
          >
            Konsultasi Gratis
          </button>
        </a>
      </div>
    </section>
  )
}

export default Consult
