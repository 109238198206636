import homeBanner from "./homeBanner.png"
import aboutUsBanner from "./aboutUsBanner.png"
import projectExample from "./projectExample.png"
import projectImage from "./projectImage.png"
import rectangle from "./rectangle.png"
import rectangleGray from "./rectangle-gray.png"
import rectangleBlack from "./rectangle-black.png"
import BannerImage from "./bannerImage.png"
import WebApp from "./webapp.png"
import MobileApp from "./mobileapp.png"
import IOT from "./iot.png"
// clients
import arista from "./clients/arista.png"
import astra from "./clients/astra.png"
import hellofit from "./clients/hellofit.png"
import mrSafety from "./clients/mrSafety.png"
import powerCharacter from "./clients/powerCharacter.png"
import toyota from "./clients/toyota.png"
import rumahsiapkerja from "./clients/rumahsiapkerja.png"
import asaba from "./clients/asaba.png"
import bluebird from "./clients/bluebird.png"

import Automotive_Auction from "./OurSolutions/Automotive_Auction.png"
import E_commerce from "./OurSolutions/E_commerce.png"
import Health_Care from "./OurSolutions/Health_Care.png"
export {
    asaba,
    bluebird,
    rumahsiapkerja,
    IOT,
    MobileApp,
    WebApp,
    BannerImage,
    rectangleGray,
    rectangleBlack,
    homeBanner,
    aboutUsBanner,
    projectExample,
    projectImage,
    rectangle,
    arista,
    astra,
    hellofit,
    mrSafety,
    powerCharacter,
    toyota,
    Automotive_Auction,
    E_commerce,
    Health_Care
}
