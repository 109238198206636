import React from "react"
export const ReloadIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 2C7.25144 2 4.82508 3.38626 3.38443 5.5H6V7.5H0V1.5H2V3.99936C3.82381 1.57166 6.72764 0 10 0C15.5228 0 20 4.47715 20 10H18C18 5.58172 14.4183 2 10 2ZM2 10C2 14.4183 5.58172 18 10 18C12.7486 18 15.1749 16.6137 16.6156 14.5H14V12.5H20V18.5H18V16.0006C16.1762 18.4283 13.2724 20 10 20C4.47715 20 0 15.5228 0 10H2Z"
                fill="#0A0A0A"
            />
        </svg>
    )
}
