import React, { useEffect, useState } from "react"
import { GiHamburgerMenu } from "react-icons/gi"
import { IoClose } from "react-icons/io5"
import { NavLink, useLocation } from "react-router-dom"
import { Whatsapp } from "../../assets/icons/Whatsap"
import { headerLogo, headerLogoFull, headerLogoBlack } from "../../assets/images/Nav"
import { PICNumber } from "../../assets/strings/variables"

const Header = ({ active, isHome,  }) => {
    const [toggle, setToggle] = useState(false)

    const location = useLocation();

    const handleToggle = () => {
        setToggle(!toggle)
    }

    useEffect(() => {
        setToggle(false)
    }, [location])
    

    const menuList = [
        {
            name: "Home",
            path: "/"
        },
        {
            name: "Ai Solution",
            path: "/ai-solution"
        },
        {
            name: "Custom Solution",
            path: "/custom-solution"
        },
        {
            name: "Our Portfolio",
            path: "/our-portfolio"
        }
    ]

    return (
        <header
            id="header"
            className={` fixed top-0 w-full z-10 codr-header ${isHome ? "home" : "bg-white"} ${
                !active ? "codr-header-active " : ""
            }`}
        >
            {/* Header container */}
            <div className="xsm:py-4 md:py-0 flex w-full md:px-10 mx-auto justify-between relative items-center">
                {/* Flex 1 : logo Container */}
                {isHome ? (
                    <NavLink to="/" className={`logoContainer z-10 ml-4 ${!active ? "hideLogo" : ""} `}>
                        <img src={headerLogoFull} className="header-logo-full" loading="lazy" alt="caready header" />
                        <img
                            src={headerLogo}
                            className={`absolute header-logo top-0 left-[21px] z-20`}
                            loading="lazy"
                            alt="caready header"
                        />
                    </NavLink>
                ) : (
                    <NavLink to="/" className={`z-10 ml-4 `}>
                        <img src={headerLogoBlack} className="" loading="lazy" alt="caready header" />{" "}
                    </NavLink>
                )}
                {/* Flex 2 : Nav Link Container */}
                <div className="md:flex w-min p-4 items-center">
                    <button className="xsm:block mx-auto md:hidden w-[25px] h-[25px]" onClick={handleToggle}>
                        <GiHamburgerMenu size={24} />
                    </button>
                    <div
                        className={`flex md:flex-row md:static md:space-x-16 md:gap-6 items-center xsm:w-full xsm:h-screen md:h-full xsm:py-5 ${isHome ? "xsm:bg-[#0a0a0a]":"bg-[#FFF]"} md:bg-transparent xsm:gap-3 xsm:absolute xsm:flex-col xsm:justify-center xsm:top-[0%] xsm:right-[0] xsm:transition-transform xsm:duration-300 xsm:ease-linear md:translate-x-[0] ${
                            toggle ? "xsm:translate-x-[0%]" : "xsm:translate-x-[100%]"
                        }`}
                    >
                        <button
                            className="xsm:absolute top-8 right-5 mx-auto md:hidden w-[25px] h-[25px]"
                            onClick={handleToggle}
                        >
                            <IoClose size={24} />
                        </button>
                        {menuList?.map((menu, i) => (
                            <NavLink
                                key={i}
                                to={menu.path}
                                // className={`!ml-0 text-center flex-nowrap w-fit `}
                                className={(navData) => {
                                    // const path = window.location.pathname
                                    let className = "!ml-0 md:!mr-8 text-center flex-nowrap whitespace-nowrap "
                                    if (isHome) {
                                        // black navbar
                                        if (navData.isActive) {
                                            className = className + " font-bold text-white"
                                        } else className = className + " hover:text-white"
                                    } else {
                                        // white navbar
                                        if (navData.isActive) {
                                            className = className + " font-bold text-black"
                                        } else className = className + " hover:text-black text-[#898989]"
                                    }
                                    return className
                                }}
                            >
                                {menu.name}
                            </NavLink>
                        ))}
                        <a href={`https://wa.me/${PICNumber}`} target="_blank" rel="noreferrer" className="!ml-0">
                            <button className="text-[#101223] bg-[#FFF] py-3 xsm:px-5 md:px-7 font-semibold rounded-full">
                                <div className="flex items-center align-middle space-x-2 whitespace-nowrap">
                                    <Whatsapp />
                                    <div>Contact Us</div>
                                </div>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
