import React from "react";

function Banner({ project }) {
  return (
    <div
      className="w-full h-[501px] overflow-hidden"
      style={{
        background: `url(${
          window.location.origin + `/img/${project.details.bannerImage}`
        }) no-repeat center`,
        backgroundSize: "cover",
      }}
    >
      <div className="flex text-white h-full max-w-[1280px] mx-auto justify-center gap-4 flex-col xsm:px-4 md:px-4 xl:px-0">
        <div className="xsm:text-4xl md:text-6xl font-semibold leading-normal tracking-wider">
          {project?.title}
        </div>
        {/* <div className="text-xl font-light">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum culpa
          voluptates labore provident nesciunt dolores quibusdam sapiente rerum
          laboriosam modi?
        </div> */}
      </div>
    </div>
  );
}

export default Banner;
