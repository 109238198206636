import React from "react";
import { Link } from "react-router-dom";
import { base } from "../../assets/strings/variables";
import CardProject from "../CardProject/CardProject";
import { someProject } from "../../pages/Homepage/homepageData";

function Projects() {
  return (
    <section id="our-projects">
      <div className="max-w-[1280px] items-center mx-auto h-full grid grid-cols-1 gap-10 sm:px-4 py-16">
        <div className="flex xsm:flex-col sm:flex-row items-center sm:justify-between xsm:gap-4">
          <div className="text-[32px] font-semibold">Some of Our Projects</div>
          <Link
            to={"/projects"}
            className={`p-1 rounded-full w-min whitespace-nowrap border border-[${base}] px-5 font-semibold`}
          >
            See Other Project
          </Link>
        </div>
        <div className="grid md:gap-16 xsm:grid-cols-1 md:grid-cols-2 xsm:gap-8 xsm:px-4">
          {someProject.map(({ id, title, details }, i) => (
            <div className="group" key={`project-${i}`}>
              <Link to={`/projects/${id}`}>
                <CardProject id={id} title={title} image={details.cardImage} />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Projects;
