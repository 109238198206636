import React from "react"
export const RunIcon = () => {
    return (
        <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.82985 7.78986L4.99998 8.45588V12H2.99997V7.05H3.01499L8.28339 5.13247C8.5274 5.03855 8.7922 4.99162 9.0648 5.0008C10.1762 5.02813 11.1522 5.75668 11.4917 6.82036C11.678 7.40431 11.848 7.79836 12.0015 8.0025C12.9138 9.2155 14.3653 10 16 10V12C13.8253 12 11.8823 11.0083 10.5984 9.4526L9.90079 13.4085L12 15.17V22H10V16.1025L7.73069 14.1984L7.003 18.3253L0.109375 17.1098L0.456665 15.1401L5.3807 16.0084L6.82985 7.78986ZM10.5 4.5C9.3954 4.5 8.5 3.60457 8.5 2.5C8.5 1.39543 9.3954 0.5 10.5 0.5C11.6046 0.5 12.5 1.39543 12.5 2.5C12.5 3.60457 11.6046 4.5 10.5 4.5Z"
                fill="#0A0A0A"
            />
        </svg>
    )
}
