import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import projectList from "../../pages/Project/listProjectData";

import CardProject from "../CardProject/CardProject";

function ProjectList() {
  const [categories, setCategories] = useState([...projectList]);
  const [pageNumber, setPageNumber] = useState(0);
  const projectListActive = categories.find(
    (cat) => cat.active == true
  )?.projects;
  const projectsPerPage = 4;
  const totalPage = Math.ceil(projectListActive.length / projectsPerPage);

  const sliderRef = useRef(null);

  const handleResize = (index) => {
    let contentIndex = (sliderRef.current.offsetWidth / totalPage) * index;
    sliderRef.current.style.translate = -contentIndex + "px";
  };

  useEffect(() => {
    handleResize(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    const listen = window.addEventListener(
      "resize",
      handleResize.bind(this, 0)
    );
    return removeEventListener(listen, null);
  }, []);

  const handleActive = (index) => {
    setCategories((prev) =>
      prev.map((cat, indx) => ({
        ...cat,
        active: indx === index ? true : false,
      }))
    );
  };

  const handleClick = (index) => {
    handleActive(index);
    handleResize(0);
    handlePage(0);
  };

  const handlePage = (index) => {
    if (index < 0 || index >= totalPage) {
      return;
    }
    setPageNumber(index);
  };

  return (
    <div className="w-full h-full relative xsm:px-4 md:pb-20">
      {/* START BUTTON PROJECT CATEGORIES */}
      <div className="max-w-[1280px] items-center xsm:static md:sticky z-[5] top-[56px] mx-auto h-full grid grid-cols-1 gap-10 sm:px-4 py-16">
        <div className="xsm:grid xsm:text-sm xsm:pt-4 bg-white lg:p-4 xsm:grid-cols-2 xsm:gap-3 md:flex md:justify-center lg:justify-start md:gap-4 flex-wrap">
          {categories.map((category, ind) => (
            <button
              type="button"
              key={`${ind}`}
              onClick={() => handleClick(ind)}
              className={`py-3 px-4 m-0 lg:px-7 font-semibold rounded-full ${
                category.active
                  ? "text-[#FFF] bg-[#0A0A0A]"
                  : "text-[#898989] bg-[#F7F7F7]"
              }`}
            >
              {category.name}
            </button>
          ))}
        </div>
      </div>
      {/* END BUTTON PROJECT CATEGORIES */}
      {/* START PROJECT GRID */}
      <div className="overflow-x-hidden">
        <div
          className="flex min-w-fit transition-[translate] duration-300 ease-linear"
          ref={sliderRef}
        >
          {new Array(totalPage).fill(1).map((page, index) => {
            const pageVisited = index * projectsPerPage;
            return (
              <div
                key={index}
                className="h-full w-screen flex lg:px-24 justify-center"
              >
                <div className="w-full h-full">
                  <div className="max-w-[1024px] mx-auto h-full grid xsm:grid-cols-1 md:grid-cols-2 gap-3 lg:gap-6 justify-center items-center">
                    {projectListActive
                      .slice(pageVisited, pageVisited + projectsPerPage)
                      .map(({ id, title, details }, index) => (
                        <div className="group" key={index}>
                          <Link to={`/projects/${id}`}>
                            <CardProject
                              id={id}
                              title={title}
                              image={details.cardImage}
                            />
                          </Link>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* END PROJECT GRID */}
        {/* START PAGINATION */}
        <div
          className={`gap-3 w-full justify-center items-center pt-10 ${
            projectListActive.length == 0 ? "hidden" : "flex"
          }`}
        >
          <div
            className={`font-semibold text-base ${
              pageNumber == 0
                ? "text-gray-500 cursor-default"
                : "text-black cursor-pointer"
            }`}
            onClick={
              pageNumber == 0 ? () => {} : handlePage.bind(this, pageNumber - 1)
            }
          >
            Prev
          </div>
          {new Array(totalPage).fill(1).map((page, index) => {
            return (
              <div
                key={index}
                className={` font-semibold text-base  ${
                  index == pageNumber
                    ? "text-black cursor-default"
                    : "text-gray-500 cursor-pointer"
                }`}
                onClick={handlePage.bind(this, index)}
              >
                {index + 1}
              </div>
            );
          })}
          <div
            className={`font-semibold text-base ${
              pageNumber == totalPage - 1
                ? "text-gray-500 cursor-default"
                : "text-black cursor-pointer"
            }`}
            onClick={
              pageNumber == totalPage - 1
                ? () => {}
                : handlePage.bind(this, pageNumber + 1)
            }
          >
            Next
          </div>
        </div>
        {/* END PAGINATION */}
      </div>
    </div>
  );
}

export default ProjectList;
