import React from "react"
export const ResizeIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.5858 2H11V0H18V7H16V3.41421L11.7071 7.7071L10.2929 6.29289L14.5858 2ZM0 11H2V14.5858L6.29289 10.2929L7.7071 11.7071L3.41421 16H7V18H0V11Z"
                fill="#0A0A0A"
            />
        </svg>
    )
}
