import React from "react"
export const MedalIcon = () => {
    return (
        <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.00009 6.5L10.1161 11.588L15.6081 12.028L11.4241 15.612L12.7021 20.972L8.00009 18.1L3.29809 20.972L4.57609 15.612L0.39209 12.028L5.88409 11.588L8.00009 6.5ZM8.00009 11.707L7.26109 13.484L5.34509 13.637L6.80509 14.888L6.35809 16.759L8.00009 15.756L9.64109 16.759L9.19509 14.889L10.6541 13.637L8.73909 13.484L8.00009 11.707ZM4.00009 0V9H2.00009V0H4.00009ZM14.0001 0V9H12.0001V0H14.0001ZM9.00009 0V5H7.00009V0H9.00009Z"
                fill="#0A0A0A"
            />
        </svg>
    )
}
