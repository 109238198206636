import Layout from "../../components/Layout/Layout";
import React from "react";
const Blog = () => {
  return (
    <Layout>
      <div>Blog</div>
    </Layout>
  );
};

export default Blog;
