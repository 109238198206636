import React from "react";
import { rectangleGray } from "../../assets/images/Home";

function About() {
  return (
    <section id="About" className="h-[437px] w-full relative xsm:px-4 xl:px-0">
      <div className="flex h-full justify-center items-center">
        <div className="flex w-full h-full justify-center items-center">
          <div className="max-w-[1280px]">
            <div className="xsm:text-lg sm:text-2xl lg:text-[32px] font-normal mb-4">
              About Us
            </div>
            <div className="xsm:text-xl sm:text-[32px] lg:text-4xl font-semibold leading-10">
              We believe in the power of design in software development. We
              thrive on building relationships and helping good people and
              organizations succeed through a collaborative process.
            </div>
          </div>
        </div>
        <img
          className="absolute xsm:w-auto xsm:h-[437px] object-cover right-0 z-[-1] top-0"
          src={rectangleGray}
          alt="backgroundAbout"
        />
      </div>
    </section>
  );
}

export default About;
