import React from "react";
function DetailsProject({ project }) {
  return (
    <div className="h-full max-w-[1024px] lg:mx-auto my-20 border-b-[1px] mx-4 border-[#C0C0C0] pb-10">
      {/* Grid Container : Details Project */}
      <div className="grid xsm:grid-cols-1 xsm:px-4 xl:px-0 md:grid-cols-[180px_1fr] xsm:gap-10 md:gap-x-2 md:gap-y-7 lg:gap-y-20 lg:gap-x-0">
        {/* Grid Overview */}
        <div className="font-semibold">Overview</div>
        <div className="flex flex-col xsm:gap-10 lg:gap-20">
          <div>{project?.details?.overview}</div>
          <div className="h-full w-full flex justify-center overflow-hidden rounded-md bg-[#F7F7F7] mb-6">
            <img
              src={
                window.location.origin +
                `/img/${project.details.productPreview}`
              }
              alt=""
              loading="lazy"
              className="h-full object-contain"
            />
          </div>
        </div>
        {/* Grid Approach */}
        <div className="font-semibold">Approach</div>
        <div>
          <div>{project?.details?.approach}</div>
        </div>
        {/* Grid Result */}
        {/* <div className="font-semibold">Result</div>
        <div>
          <div>{project?.details?.result}</div>
        </div> */}
      </div>
    </div>
  );
}

export default DetailsProject;
