import React from "react";
import { secondary } from "../../assets/strings/variables";

const CardProject = ({ title, image }) => {
  return (
    <>
      <div className="h-[310px] w-full scale-100 object-cover flex justify-center overflow-hidden rounded-md bg-[#F7F7F7] mb-6">
        <img
          // src={projectExample}
          src={window.location.origin + `/img/${image ?? "projectExample.png"}`}
          alt=""
          loading="lazy"
          className="h-full object-contain group-hover:scale-[1.3] transition-transform duration-300 ease-linear "
        />
      </div>
      <div className={`text-[${secondary}] flex flex-col space-y-3`}>
        <div className="font-semibold text-black xsm:text-xl md:text-2xl">
          {title ?? ""}
        </div>
        {/* <div className="text-base">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. At in sed in
          libero dictumst. Quis leo nascetur gravida leo.
        </div> */}
        <div className="text-sm cursor-pointer">Read more</div>
      </div>
    </>
  );
};

export default CardProject;
