import React from "react"
export const InfinityIcon = () => {
    return (
        <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2 6C2 4.067 3.567 2.5 5.5 2.5C6.7035 2.5 7.51959 2.9338 8.19914 3.61336C8.9255 4.3397 9.4851 5.3322 10.1258 6.4856L10.1595 6.5462C10.7605 7.6283 11.4431 8.8573 12.3866 9.8009C13.3946 10.8088 14.7035 11.5 16.5 11.5C19.5376 11.5 22 9.0376 22 6C22 2.96243 19.5376 0.5 16.5 0.5C14.8394 0.5 13.3508 1.2359 12.3423 2.39937C12.7887 3.05406 13.1574 3.70577 13.464 4.2574C14.0681 3.20718 15.2014 2.5 16.5 2.5C18.433 2.5 20 4.067 20 6C20 7.933 18.433 9.5 16.5 9.5C15.2965 9.5 14.4804 9.0662 13.8009 8.3866C13.0745 7.6603 12.5149 6.6678 11.8742 5.5144L11.8405 5.4538C11.2395 4.3717 10.5569 3.14265 9.6134 2.19914C8.60541 1.1912 7.2965 0.5 5.5 0.5C2.46243 0.5 0 2.96243 0 6C0 9.0376 2.46243 11.5 5.5 11.5C7.16056 11.5 8.64923 10.7641 9.6577 9.6006C9.2113 8.9459 8.84262 8.2942 8.53605 7.7426C7.93194 8.7928 6.79856 9.5 5.5 9.5C3.567 9.5 2 7.933 2 6Z"
                fill="#0A0A0A"
            />
        </svg>
    )
}
