import React from "react"
import { secondary } from "../../assets/strings/variables"
import { FAQList } from "../../pages/Homepage/homepageData"
import FAQContainer from "../FAQContainer/FAQContainer"

function FAQ() {
  return (
    <section id="frequently-asked-question">
      <div className="w-full px-4 sm:px-10 h-full grid grid-cols-1">
        <div className="flex flex-col space-y-3 text-center mx-auto md:w-[613px]">
          <div className="text-[32px] font-semibold">
            Frequently Asked Question
          </div>
          <div className={`text-2xl text-[${secondary}]`}>
            Below you’ll find the answers to the questions we get asked most
            about
          </div>
        </div>
        <div className="my-10">
          {FAQList.map((faq, indx) => (
            <div
              className={`py-6 ${
                indx !== FAQList.length - 1 ? "border-b" : ""
              }`}
              key={`FAQ-${indx}`}
            >
              <FAQContainer data={faq} />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default FAQ
