import React from "react"
export const ChatIcon = () => {
    return (
        <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.76282 14H11.083C11.0284 14.3252 11 14.6593 11 15C11 15.3407 11.0284 15.6748 11.083 16H4.45455L0 19.5V1C0 0.44772 0.44772 0 1 0H19C19.5523 0 20 0.44772 20 1V9.8027C19.3926 9.4513 18.7179 9.2034 18 9.083V2H2V15.3851L3.76282 14ZM21.9497 18.5355L20.4462 17.032C20.7981 16.4365 21 15.7418 21 15C21 12.7909 19.2091 11 17 11C14.7909 11 13 12.7909 13 15C13 17.2091 14.7909 19 17 19C17.7418 19 18.4365 18.7981 19.032 18.4462L20.5355 19.9497L21.9497 18.5355ZM19 15C19 16.1046 18.1046 17 17 17C15.8954 17 15 16.1046 15 15C15 13.8954 15.8954 13 17 13C18.1046 13 19 13.8954 19 15Z"
                fill="#0A0A0A"
            />
        </svg>
    )
}
