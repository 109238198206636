import Layout from "../../components/Layout/Layout";
import React from "react";
import Hero from "../../components/Projects/Hero";
import ProjectList from "../../components/Projects/ProjectList";
import Contact from "../../components/Projects/Contact";
const Project = () => {
  return (
    <Layout>
      <Hero />
      <ProjectList />
      <Contact />
    </Layout>
  );
};

export default Project;
